// Service Image Icons
import mainIcon1 from '../assets/img/service/style2/main-img/1.png';
import hoverIcon1 from '../assets/img/service/style2/hover-img/1.png';
import mainIcon2 from '../assets/img/service/style2/main-img/2.png';
import hoverIcon2 from '../assets/img/service/style2/hover-img/2.png';
import mainIcon3 from '../assets/img/service/style2/main-img/3.png';
import hoverIcon3 from '../assets/img/service/style2/hover-img/3.png';
import mainIcon4 from '../assets/img/service/style2/main-img/4.png';
import hoverIcon4 from '../assets/img/service/style2/hover-img/4.png';
import mainIcon5 from '../assets/img/service/style2/main-img/5.png';
import hoverIcon5 from '../assets/img/service/style2/hover-img/5.png';
import mainIcon6 from '../assets/img/service/style2/main-img/6.png';
import hoverIcon6 from '../assets/img/service/style2/hover-img/6.png';
import softwareIconMain from '../assets/img/service/style3/main-img/1.png'
import softwareIconHover from '../assets/img/service/style3/hover-img/1.png'
import cryptoIconMain from '../assets/img/service/style3/main-img/8.png'
import cryptoIconHover from '../assets/img/service/style3/hover-img/8.png'
import ecommIconMain from '../assets/img/service/style3/main-img/9.png'
import ecommIconHover from '../assets/img/service/style3/hover-img/9.png'

class Service {
    constructor(title, text, mainIcon, hoverIcon) {
        this.title = title;
        this.text = text;
        this.mainIcon = mainIcon;
        this.hoverIcon = hoverIcon;
    }
}

export const ServiceList = [
    new Service(
        "Legal consulting",
        "Strategic consulting to help you align your business goals with legal requirements.",
        mainIcon1,
        hoverIcon1,
    ),
    // new Service(
    //     "Technology consulting",
    //     "Strategic technology consulting to help you align your business goals with technology solutions.",
    //     mainIcon1,
    //     hoverIcon1,
    // ),
    // new Service(
    //     "Software Development",
    //     "Cutting-edge customized software solutions tailored to meet your business needs.",
    //     softwareIconMain,
    //     softwareIconHover,
    // ),
    // new Service(
    //     "Analytic Solutions",
    //     "Advanced analytics and business intelligence solutions to help you make data-driven decisions.",
    //     mainIcon3,
    //     hoverIcon3,
    // ),
    // new Service(
    //     "Clould & DevOps",
    //     "Cloud-based infrastructure and DevOps practices to improve scalability and reliability.",
    //     mainIcon4,
    //     hoverIcon4,
    // ),
    // new Service(
    //     "Web & App Development",
    //     "Responsive and user-friendly websites and cross-platform applications to enhance online presence.",
    //     mainIcon2,
    //     hoverIcon2,
    // ),
    // new Service(
    //     "Product & Design",
    //     "Creating products and design solutions to create innovative and engaging user experiences",
    //     mainIcon5,
    //     hoverIcon5,
    // ),
    // new Service(
    //     "Database Design",
    //     "Comprehensive database design and administration services to ensure optimal performance and data security.",
    //     mainIcon6,
    //     hoverIcon6,
    // ),
    // new Service(
    //     "E-commerce",
    //     "End-to-end e-commerce solutions to help you launch, manage and grow your online business.",
    //     ecommIconMain,
    //     ecommIconHover,
    // ),
    // new Service(
    //     "Blockchain, NFT and Crypto",
    //     "Expertise in blockchain, NFT, and cryptocurrency technologies for secure and transparent transactions.",
    //     cryptoIconMain,
    //     cryptoIconHover,
    // )
];